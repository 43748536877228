import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { library } from "@fortawesome/fontawesome-svg-core";
/* Available fontAwesome icon sets to this project are:
    "@fortawesome/free-solid-svg-icons"
    "@fortawesome/pro-duotone-svg-icons"
    "@fortawesome/pro-light-svg-icons"
    "@fortawesome/pro-regular-svg-icons"
    "@fortawesome/pro-solid-svg-icons"
*/
import { faCalendarDays } from "@fortawesome/pro-light-svg-icons/faCalendarDays";
import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons/faCheckCircle";
import { faCircleCheck } from "@fortawesome/pro-light-svg-icons/faCircleCheck";
import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons/faCircleExclamation";
import { faClock } from "@fortawesome/pro-light-svg-icons/faClock";
import { faCloudArrowUp } from "@fortawesome/pro-light-svg-icons/faCloudArrowUp";
import { faCopy } from "@fortawesome/pro-light-svg-icons/faCopy";
import { faDownload } from "@fortawesome/pro-light-svg-icons/faDownload";
import { faEllipsisVertical } from "@fortawesome/pro-light-svg-icons/faEllipsisVertical";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons/faExclamationTriangle";
import { faFileLines } from "@fortawesome/pro-light-svg-icons/faFileLines";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons/faInfoCircle";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons/faMagnifyingGlass";
import { faMeh } from "@fortawesome/pro-light-svg-icons/faMeh";
import { faReply } from "@fortawesome/pro-light-svg-icons/faReply";
import { faThumbsDown } from "@fortawesome/pro-light-svg-icons/faThumbsDown";
import { faThumbsUp } from "@fortawesome/pro-light-svg-icons/faThumbsUp";
import { faTrashCan } from "@fortawesome/pro-light-svg-icons/faTrashCan";
import { faTrashCanUndo } from "@fortawesome/pro-light-svg-icons/faTrashCanUndo";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons/faTriangleExclamation";
import { faXmark } from "@fortawesome/pro-light-svg-icons/faXmark";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { faAngleLeft } from "@fortawesome/pro-regular-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons/faAngleRight";
import { faAngleUp } from "@fortawesome/pro-regular-svg-icons/faAngleUp";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons/faCircleInfo";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import { faCircleCheck as faCircleCheckSolid } from "@fortawesome/pro-solid-svg-icons/faCircleCheck";
import { faCircleX } from "@fortawesome/pro-solid-svg-icons/faCircleX";
import { faClock as faSolidClock } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faXmark as faSolidXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { faArrowRightFromBracket } from "@fortawesome/pro-light-svg-icons/faArrowRightFromBracket";
import { faScrewdriverWrench } from "@fortawesome/pro-light-svg-icons/faScrewdriverWrench";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight";
import { faDiamondExclamation } from "@fortawesome/pro-regular-svg-icons/faDiamondExclamation";
import { faInfoCircle as faRegularInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faExclamationTriangle as faRegularExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";
import { faCircleCheck as faRegularCircleCheck } from "@fortawesome/pro-regular-svg-icons/faCircleCheck";
import { faArrowUpLong } from "@fortawesome/pro-solid-svg-icons/faArrowUpLong";
import { faArrowDownLong } from "@fortawesome/pro-solid-svg-icons/faArrowDownLong";
import { faGripLines } from "@fortawesome/pro-regular-svg-icons/faGripLines";
import { faCalendar } from "@fortawesome/pro-light-svg-icons/faCalendar";
import { faAngleDoubleRight } from "@fortawesome/pro-regular-svg-icons/faAngleDoubleRight";
import { faAngleDoubleLeft } from "@fortawesome/pro-regular-svg-icons/faAngleDoubleLeft";
import { faTable } from "@fortawesome/pro-light-svg-icons/faTable";

export const initIconLibrary = (...icons: IconDefinition[]) =>
  library.add(
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faCalendarDays,
    faCaretDown,
    faCaretRight,
    faCaretUp,
    faCheck,
    faCheckCircle,
    faCircleCheck,
    faCircleCheckSolid,
    faCircleExclamation,
    faCircleInfo,
    faCircleX,
    faClock,
    faCloudArrowUp,
    faCopy,
    faDownload,
    faEllipsisVertical,
    faExclamationCircle,
    faExclamationTriangle,
    faFileLines,
    faInfoCircle,
    faMagnifyingGlass,
    faMeh,
    faReply,
    faSolidClock,
    faThumbsDown,
    faThumbsUp,
    faTrashCan,
    faTrashCanUndo,
    faTriangleExclamation,
    faXmark,
    faSolidXmark,
    faArrowRightFromBracket,
    faScrewdriverWrench,
    faArrowRight,
    faDiamondExclamation,
    faRegularInfoCircle,
    faRegularExclamationTriangle,
    faRegularCircleCheck,
    faArrowUpLong,
    faArrowDownLong,
    faGripLines,
    faCalendar,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faTable,
    ...icons
  );
