import * as React from "react";

import { ErrorBoundaryPage } from "../ErrorPage";
import { DatadogErrorBoundary } from "../Datadog/ErrorBoundary";
import type { DatadogErrorBoundaryProps } from "../Datadog/ErrorBoundary";
import { PageLoader } from "../PageLoader";
export interface ErrorBoundaryProps extends DatadogErrorBoundaryProps {
  children?: React.ReactNode;
}
/**
 * A generic error boundary component that sends events to Datadog. It is preconfigured to
 * log to cypress and the console, and will fallback to ErrorBoundaryPage.
 *
 * @remarks You shouldn't need to modify `onError`, and should only pass a `fallback` component if required
 */
export const ErrorBoundary = ({ children, ...props }: ErrorBoundaryProps) => (
  <DatadogErrorBoundary
    fallback={ErrorBoundaryPage}
    onError={(error) => {
      console.log("ErrorBoundary onError:", error.toString());
      (window as any).Cypress?.log({
        name: "ErrorBoundary Error",
        message: error.toString(),
      });
    }}
    {...props}
  >
    {children}
  </DatadogErrorBoundary>
);

export const ErrorBoundaryReloadOnDynamicImport = ({
  children,
  ...props
}: ErrorBoundaryProps) => {
  return (
    <DatadogErrorBoundary
      fallback={({ error }) => {
        if (
          error.message.includes("Failed to fetch dynamically imported module:")
        ) {
          window.location.reload();
          return <PageLoader />;
        }
        return <ErrorBoundaryPage error={error} />;
      }}
      onError={(error) => {
        console.log("ErrorBoundary onError:", error.toString());
        (window as any).Cypress?.log({
          name: "ErrorBoundary Error",
          message: error.toString(),
        });
      }}
      {...props}
    >
      {children}
    </DatadogErrorBoundary>
  );
};
