import { userInteractivesDefaults } from "../defaultStylings";

export default {
  variants: {
    outline: () => ({
      field: userInteractivesDefaults,
    }),
  },
  defaultProps: {
    size: "sm",
  },
};
