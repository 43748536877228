import * as React from "react";
import { Center, Spinner } from "@chakra-ui/react";
import type { CenterProps } from "@chakra-ui/react";

export const PageLoader = (props: CenterProps) => (
  <Center
    {...{
      position: "fixed",
      w: "100vw",
      h: "100vh",
      top: 0,
      left: 0,
      pointerEvents: "none",
      ...props,
    }}
  >
    <Spinner
      size="xl"
      thickness="0.25rem"
      data-testid="page-loader"
      variant="pageLoader"
    />
  </Center>
);
