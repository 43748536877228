export default {
  transparent: "transparent",
  white: "#FFFFFF",
  black: "#111111",
  brand: "#3646F5",
  volt: "#6BFF29",

  whiteAlpha: {
    50: "rgba(255, 255, 255, 0.04)",
    100: "rgba(255, 255, 255, 0.06)",
    200: "rgba(255, 255, 255, 0.08)",
    300: "rgba(255, 255, 255, 0.16)",
    400: "rgba(255, 255, 255, 0.24)",
    500: "rgba(255, 255, 255, 0.36)",
    600: "rgba(255, 255, 255, 0.48)",
    700: "rgba(255, 255, 255, 0.64)",
    800: "rgba(255, 255, 255, 0.80)",
    900: "rgba(255, 255, 255, 0.92)",
  },

  blackAlpha: {
    50: "rgba(0, 0, 0, 0.04)",
    100: "rgba(0, 0, 0, 0.06)",
    200: "rgba(0, 0, 0, 0.08)",
    300: "rgba(0, 0, 0, 0.16)",
    400: "rgba(0, 0, 0, 0.24)",
    500: "rgba(0, 0, 0, 0.36)",
    600: "rgba(0, 0, 0, 0.48)",
    700: "rgba(0, 0, 0, 0.64)",
    800: "rgba(0, 0, 0, 0.80)",
    900: "rgba(0, 0, 0, 0.92)",
  },

  green: {
    50: "#E8F8EC",
    100: "#D1F1D8",
    200: "#A4E4B2",
    300: "#76D68B",
    400: "#49C965",
    500: "#1BBB3E",
    600: "#18A838",
    700: "#13832B",
    800: "#0E5E1F",
    900: "#083813",
  },
  teal: {
    50: "#E7F8F9",
    100: "#D0F1F3",
    200: "#A0E4E7",
    300: "#71D6DC",
    400: "#41C9D0",
    500: "#12BBC4",
    600: "#10A8B0",
    700: "#0D8389",
    800: "#095E62",
    900: "#05383B",
  },
  cyan: {
    50: "#E5F4FB",
    100: "#CCE9F7",
    200: "#99D2EF",
    300: "#66BCE6",
    400: "#33A5DE",
    500: "#008FD6",
    600: "#0081C1",
    700: "#006496",
    800: "#00486B",
    900: "#002B40",
  },
  blue: {
    50: "#EBECFE",
    100: "#D7DAFD",
    200: "#AFB5FB",
    300: "#8690F9",
    400: "#5E6BF7",
    500: "#3646F5",
    600: "#313FDC",
    700: "#2631AC",
    800: "#1B237A",
    900: "#10154A",
  },
  purple: {
    50: "#F3EAFF",
    100: "#E8D5FF",
    200: "#D1ABFF",
    300: "#BA82FF",
    400: "#A358FF",
    500: "#8C2EFF",
    600: "#7E29E6",
    700: "#6220B3",
    800: "#461780",
    900: "#2A0E4D",
  },
  gray: {
    50: "#FAFAFA",
    100: "#F2F2F2",
    200: "#E9E9E9",
    300: "#E0E0E0",
    400: "#CDCDCD",
    500: "#BDBDBD",
    600: "#A0A0A0",
    700: "#828282",
    800: "#4F4F4F",
    900: "#333333",
  },
  magenta: {
    50: "#FAE9F6",
    100: "#F5D4ED",
    200: "#EBA9DB",
    300: "#E07EC9",
    400: "#D653B7",
    500: "#CC28A5",
    600: "#B82494",
    700: "#8F1C74",
    800: "#661452",
    900: "#3D0C32",
  },
  red: {
    50: "#FDE8EB",
    100: "#FCD2D7",
    200: "#F8A5AF",
    300: "#F57887",
    400: "#F14B5F",
    500: "#EE1E37",
    600: "#D61B32",
    700: "#A71527",
    800: "#770F1C",
    900: "#470911",
  },
  orange: {
    50: "#FEF0EA",
    100: "#FDE2D6",
    200: "#FBC4AD",
    300: "#F8A784",
    400: "#F6895B",
    500: "#F46C32",
    600: "#DC612D",
    700: "#AB4C23",
    800: "#7A3619",
    900: "#49200F",
  },
  mango: {
    50: "#FFF7E7",
    100: "#FFF0D0",
    200: "#FFE0A1",
    300: "#FFD172",
    400: "#FFC143",
    500: "#FFB214",
    600: "#E6A012",
    700: "#B37D0E",
    800: "#80590A",
    900: "#4D3506",
  },
  yellow: {
    50: "#FEFCED",
    100: "#FCF9DC",
    200: "#FAF3B9",
    300: "#F7EE96",
    400: "#F5E873",
    500: "#F2E250",
    600: "#DACB48",
    700: "#A99E38",
    800: "#797128",
    900: "#494418",
  },
};
