import * as React from "react";
import { datadogRum } from "@datadog/browser-rum";
import type { RumGlobal } from "@datadog/browser-rum";

import { useConfig } from "../../hooks/useConfig";

export type DatadogProviderContextValue = {
  instance: null | RumGlobal;
};
export const DatadogProviderContext =
  React.createContext<DatadogProviderContextValue>({
    instance: null,
  });

export interface DataDogProviderProps {
  children: React.ReactNode;
  version?: string;
}

// Note: it's not really necessary to throw `datadogRum` into state in the provider, but we're doing this for ease of use if the underlying implementation changes.
export const DatadogProvider = ({
  children,
  version,
}: DataDogProviderProps) => {
  const {
    DATADOG_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN,
    DATADOG_SERVICE_NAME = "unlabeled-service",
    DATADOG_ENV = "nonprod",
  } = useConfig();
  const [instance, setInstance] = React.useState(null);

  React.useEffect(() => {
    if (!DATADOG_APPLICATION_ID || !DATADOG_CLIENT_TOKEN) return;

    if (!instance) {
      const newInstance = datadogRum;
      newInstance.init({
        applicationId: DATADOG_APPLICATION_ID,
        clientToken: DATADOG_CLIENT_TOKEN,
        site: "datadoghq.com",
        service: DATADOG_SERVICE_NAME,
        env: DATADOG_ENV,
        sampleRate: 100,
        replaySampleRate: 100,
        trackInteractions: true,
        trackFrustrations: true,
        version,
      });
      newInstance?.startSessionReplayRecording();
      setInstance(newInstance);
    }
  }, [
    DATADOG_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN,
    DATADOG_SERVICE_NAME,
    DATADOG_ENV,
    instance,
    version,
  ]);

  return (
    <DatadogProviderContext.Provider value={{ instance }}>
      {children}
    </DatadogProviderContext.Provider>
  );
};

export function useDatadogRUM(): RumGlobal {
  const context = React.useContext(DatadogProviderContext);
  if (context === undefined) {
    throw new Error("useDatadogRUM must be used within a DatadogProvider");
  }
  return context.instance;
}
