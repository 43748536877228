import type { SystemStyleObject } from "@chakra-ui/react";

const baseStyle: SystemStyleObject = {
  animationDuration: "0.65s",
  color: "blue.500",
};

const defaultProps = {
  size: "md",
};

const variants = {
  pageLoader: {
    borderBottomColor: "gray.200",
    borderLeftColor: "gray.200",
  },
};

export default {
  baseStyle,
  variants,
  defaultProps,
};
