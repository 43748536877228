import {
  extendTheme,
  withDefaultVariant,
  withDefaultColorScheme,
  withDefaultProps,
} from "@chakra-ui/react";

import { colors, typography, semanticTokens } from "./foundations";
import {
  Input,
  Radio,
  Table,
  Tabs,
  Form,
  FormError,
  FormLabel,
  Button,
  Badge,
  Menu,
  Spinner,
  Select,
  Card,
  Breadcrumb,
  Text,
  Alert,
  EmptyData,
  Divider,
  Tooltip,
  Textarea,
  NumberInput,
  Link,
} from "./components";

const overrides = {
  styles: {
    global: {
      body: {
        lineHeight: "shorter",
        bg: "body",
        color: "text",
      },
    },
  },
  colors,
  semanticTokens,
  ...typography,
  components: {
    Alert,
    EmptyData,
    Breadcrumb,
    Card,
    Form,
    FormError,
    FormLabel,
    Input,
    Radio,
    Table,
    Tabs,
    Button,
    Badge,
    Menu,
    Spinner,
    Select,
    Text,
    Textarea,
    Divider,
    Tooltip,
    NumberInput,
    Link,
  },
};

export const theme: any = extendTheme(
  overrides,
  withDefaultVariant({
    variant: "solid",
    components: ["Button"],
  }),
  withDefaultColorScheme({
    colorScheme: "blue",
  }),
  withDefaultProps({
    defaultProps: {
      color: "gray.800",
    },
  })
);
