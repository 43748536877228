import * as React from "react";
import userflow from "userflow.js";
import { useQuery } from "@tanstack/react-query";

import UserflowContext from "../../context/UserflowContext";
import { useUserDetails } from "../../hooks/useUserDetails";
import { useConfig } from "../../hooks";

export const UserflowProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { USERFLOW_CLIENT_KEY } = useConfig();
  // When our environment has a userflow client key, we should consider userflow to be active. In development, we most likely won't have this set.
  // This should be reserved for staging + production environments.
  const shouldUseUserFlow = Boolean(USERFLOW_CLIENT_KEY);

  const { data: userDetails } = useUserDetails();

  const result = useQuery({
    queryKey: ["userflow", userDetails?.userId],
    queryFn: async () => {
      userflow.init(USERFLOW_CLIENT_KEY);
      await userflow.identify(userDetails.userId);
    },
    enabled: Boolean(userDetails?.userId && shouldUseUserFlow),
    staleTime: Number.POSITIVE_INFINITY,
    cacheTime: Number.POSITIVE_INFINITY,
    onError: console.error,
  });

  return shouldUseUserFlow ? (
    <UserflowContext.Provider value={{ isReady: result.isSuccess, userflow }}>
      {children}
    </UserflowContext.Provider>
  ) : (
    <>{children}</>
  );
};
