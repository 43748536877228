import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useHistory } from "react-router-dom";

import { parseCookie } from "../../helpers";
import { AuthenticationContext } from "../../context";
import { useConfig } from "../../hooks/useConfig";

export const AuthenticationProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const config = useConfig();
  const { push } = useHistory();

  const domain = config.AUTH0_DOMAIN;
  const clientId = config.AUTH0_CLIENT_ID_FE;

  // This check is here because ConfigProvider does not currently block rendering children, and it's possible for these to be undefined
  // This is a check to make sure those values are resolved, but ideally should be able to be removed in the near future.
  if (!domain || !clientId) return null;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={`${window.location.origin}/authenticate`}
      audience={config.AUTH0_AUDIENCE}
      onRedirectCallback={(appState) => {
        // if the user is trying to navigate to a specific page,
        // route them to back to it after logging in,
        // otherwise, route them to the root of the app
        if (appState?.returnTo !== "/") {
          push(appState?.returnTo);
        } else {
          push("/");
        }
      }}
      useRefreshTokens
    >
      <AuthenticationProviderInner>{children}</AuthenticationProviderInner>
    </Auth0Provider>
  );
};

// This might look unnecessary, but doing this allows to easily mock the Auth0 client
// in apps that consume this repo without worrying about module mocks, module hoisting issues,
// or the differences between jest, vitest, ava, etc.
function AuthenticationProviderInner({
  children,
}: {
  children: React.ReactNode;
}) {
  const auth0Context = useAuth0();
  const hasCypressToken = !!parseCookie("e2e")?.token;

  return (
    <AuthenticationContext.Provider
      value={{
        ...auth0Context,
        isAuthenticated: hasCypressToken || auth0Context.isAuthenticated,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

export default AuthenticationProvider;
