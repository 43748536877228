import type { ComponentSingleStyleConfig } from "@chakra-ui/react";

import { pickSpecificColor } from "../utils";

export default {
  baseStyle: {
    fontSize: "sm",
    fontWeight: "medium",
    borderRadius: "base",
  },
  variants: {
    link: {
      lineHeight: 5,
    },
    outline: (props) => {
      const { colorScheme } = props;
      if (props.colorMode === "dark") return {};

      return {
        color: pickSpecificColor(colorScheme, {
          gray: 800,
          blue: 500,
        }),
        borderColor: pickSpecificColor(colorScheme, {
          gray: 300,
          blue: 500,
        }),
        _hover: {
          color: pickSpecificColor(colorScheme, {
            gray: 800,
            blue: 600,
          }),
          borderColor: pickSpecificColor(colorScheme, {
            gray: 300,
            blue: 600,
          }),
          bg: `${colorScheme}.100`,
        },
        _active: {
          color: pickSpecificColor(colorScheme, {
            gray: 800,
            blue: 600,
          }),
          borderColor: pickSpecificColor(colorScheme, {
            gray: 300,
            blue: 600,
          }),
          bg: `${colorScheme}.100`,
        },
      };
    },
  },
  defaultProps: {
    colorScheme: "blue",
    size: "sm",
  },
  sizes: {
    xs: {
      fontSize: "sm",
    },
  },
} as ComponentSingleStyleConfig;
