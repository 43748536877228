/* Available fontAwesome icon sets to this project are:
    "@fortawesome/free-solid-svg-icons"
    "@fortawesome/pro-duotone-svg-icons"
    "@fortawesome/pro-light-svg-icons"
    "@fortawesome/pro-regular-svg-icons"
    "@fortawesome/pro-solid-svg-icons"
*/
import { faBuildings } from "@fortawesome/pro-light-svg-icons/faBuildings";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { faExchange } from "@fortawesome/pro-light-svg-icons/faExchange";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faWarehouse } from "@fortawesome/pro-light-svg-icons/faWarehouse";
import { faFileImport } from "@fortawesome/pro-light-svg-icons/faFileImport";
import { faClipboardList } from "@fortawesome/pro-light-svg-icons/faClipboardList";
import { faUsers } from "@fortawesome/pro-light-svg-icons/faUsers";
import { faGear } from "@fortawesome/pro-light-svg-icons/faGear";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import { faPenToSquare } from "@fortawesome/pro-light-svg-icons/faPenToSquare";
import { faMemoCircleInfo } from "@fortawesome/pro-light-svg-icons/faMemoCircleInfo";
import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { faTruckFast } from "@fortawesome/pro-light-svg-icons/faTruckFast";
import { faAddressBook } from "@fortawesome/pro-light-svg-icons/faAddressBook";
import { faRefresh } from "@fortawesome/pro-light-svg-icons/faRefresh";
import { faShelves } from "@fortawesome/pro-light-svg-icons/faShelves";
import { faXmark } from "@fortawesome/pro-light-svg-icons/faXmark";
import { faCalendarCircleExclamation } from "@fortawesome/pro-light-svg-icons/faCalendarCircleExclamation";
import { faLaptopCode } from "@fortawesome/pro-light-svg-icons/faLaptopCode";
import { faAddressCard } from "@fortawesome/pro-light-svg-icons/faAddressCard";
import { faPen } from "@fortawesome/pro-light-svg-icons/faPen";
import { faBoltSlash } from "@fortawesome/pro-light-svg-icons/faBoltSlash";
import { faBolt } from "@fortawesome/pro-light-svg-icons/faBolt";
import { faEquals } from "@fortawesome/pro-regular-svg-icons/faEquals";
import { faAnglesUp } from "@fortawesome/pro-regular-svg-icons/faAnglesUp";
import { faCode } from "@fortawesome/pro-light-svg-icons/faCode";
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";

import { initIconLibrary } from "@stordco/fe-components";

export const initIcons = () =>
  initIconLibrary(
    faBuildings,
    faChevronDown,
    faExchange,
    faTimes,
    faWarehouse,
    faFileImport,
    faClipboardList,
    faUsers,
    faGear,
    faEllipsisVertical,
    faPenToSquare,
    faMemoCircleInfo,
    faCheck,
    faTruckFast,
    faAddressBook,
    faShelves,
    faXmark,
    faCalendarCircleExclamation,
    faLaptopCode,
    faAddressCard,
    faPen,
    faBoltSlash,
    faBolt,
    faEquals,
    faAnglesUp,
    faCode,
    faPlus,
    faRefresh
  );
