import type { SnippetOptions } from "@fullstory/browser";

export type FullstoryInterface = typeof import("@fullstory/browser");
export type MaybeFullstoryInstance = FullstoryInterface | undefined;

let instance: MaybeFullstoryInstance;

let currentOrgId: string | undefined;

const canUseFullStory = () =>
  currentOrgId &&
  process.env.NODE_ENV !== "test" && // Skip jest
  !(window as any).Cypress; // Skip Cypress

async function getInstance(): Promise<MaybeFullstoryInstance> {
  if (!canUseFullStory()) return;
  if (!instance) {
    instance = await import("@fullstory/browser");
  }
  return instance;
}

// Init needs to be called before any other method for Fullstory. If it's not, they will all noop to prevent
// Fullstory from throwing errors.
async function init(options: SnippetOptions): Promise<MaybeFullstoryInstance> {
  const { orgId } = options;
  currentOrgId = orgId;

  if (!orgId) {
    console.warn("Fullstory init called without an orgId and will be ignored");
    return;
  }

  const fs = await getInstance();
  fs?.init(options);

  return fs;
}

async function identify(...args: Parameters<FullstoryInterface["identify"]>) {
  const fs = await getInstance();
  fs?.identify(...args);
}

async function event(...args: Parameters<FullstoryInterface["event"]>) {
  const fs = await getInstance();
  fs?.event(...args);
}

export const Fullstory = {
  init,
  identify,
  event,
  getInstance,
};
