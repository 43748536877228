import * as React from "react";

import { useConfig } from "../../hooks";

export const Logo = ({ className }: { className?: string }): JSX.Element => {
  const { CDN_HOST } = useConfig();
  if (!CDN_HOST) return null;
  return (
    <img
      alt="Stord Logo"
      src={`${CDN_HOST}/assets/StordLogo.svg`}
      className={className}
    />
  );
};
