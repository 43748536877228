// TODO: these formatters are currently only used in this repo, but they could be generalized for usage
// across all stord clients.
export const formatDate = (
  date: Date,
  year: Intl.DateTimeFormatOptions["year"] = "2-digit",
  month: Intl.DateTimeFormatOptions["month"] = "2-digit",
  day: Intl.DateTimeFormatOptions["day"] = "2-digit"
): string => {
  if (!date) {
    return null;
  }

  const options: Intl.DateTimeFormatOptions = {
    month,
    day,
    year,
    timeZone: "UTC",
  };

  return new Intl.DateTimeFormat("en-US", options as any)
    .format(date)
    .replace(/\//g, "-");
};

export const formatLongDate = (date: Date): string => {
  if (!date) {
    return null;
  }

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const formatTime = (
  date: Date,
  timeZone: Intl.DateTimeFormatOptions["timeZone"] = "Etc/UTC"
): string => {
  if (!date) {
    return null;
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    timeZone,
    timeZoneName: "short",
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const getTimeStampString = (date: Date): string => {
  return `${formatTime(date)} ${formatDate(date)}`;
};

export const getDateTimeTzString = (
  date: string,
  time?: string,
  utcOffset?: string,
  timeZone?: string
): { time: string; date: string } => {
  const dateTimeStrings: { time: string; date: string } = {
    time: null,
    date: null,
  };

  if (date) {
    dateTimeStrings.date = formatDate(new Date(date));
  }

  if (time && utcOffset && timeZone) {
    dateTimeStrings.time = formatTime(
      new Date(date + "T" + time + utcOffset),
      timeZone
    );
  }
  return dateTimeStrings;
};

export const parseCookie = (cname: string): any => {
  if (
    ![
      "localhost",
      "warehouse.staging.stord.com",
      "shipper.staging.stord.com",
      "admin.staging.stord.com",
    ].includes(location.hostname)
  ) {
    return false;
  }
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return JSON.parse(c.substring(name.length, c.length));
    }
  }
  return null;
};
