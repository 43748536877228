import { lazy, Fragment, Suspense } from "react";
import ReactDOM from "react-dom";
import type { SetupWorkerApi } from "msw";

import { PageLoader, StordApp } from "@stordco/fe-components";

import "./styles/global.css";

import { initIcons } from "./config/fontAwesome";
const App = lazy(() => import("./components/App"));

initIcons();

const isDev = process.env.NODE_ENV === "development";

const prepareWorker = async () => {
  if (isDev) {
    const { MSWToolbar } = await import("@stordco/msw-toolbar");
    const { setupWorker } = await import("msw");
    const {
      cancelOrderLines,
      facilities,
      companies,
      networks,
      inventory,
      users,
      migrateUsers,
      migrateItems,
      createShipper,
      timezones,
      reasonCodes,
    } = await import("./handlers");
    const worker = setupWorker(
      ...[
        cancelOrderLines,
        facilities,
        companies,
        networks,
        inventory,
        users,
        migrateUsers,
        migrateItems,
        createShipper,
        timezones,
        reasonCodes,
      ].flat()
    );

    (window as any).worker = worker;

    return { worker, MSWToolbar };
  }
  return { worker: null, MSWToolbar: Fragment };
};

async function renderApp({
  worker,
  MSWToolbar,
}: {
  worker: SetupWorkerApi;
  MSWToolbar: any;
}) {
  ReactDOM.render(
    <Suspense fallback={<PageLoader />}>
      <MSWToolbar
        worker={worker}
        isEnabled={isDev}
        apiUrl="/api"
        prefix="admin"
        position="bottom"
      >
        <StordApp version={__APP_VERSION__}>
          <App />
        </StordApp>
      </MSWToolbar>
    </Suspense>,
    document.getElementById("root")
  );
}

prepareWorker().then(({ worker, MSWToolbar }) => {
  renderApp({ worker, MSWToolbar });
});
