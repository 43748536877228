import * as React from "react";

import { useConfig } from "../../../hooks";

declare global {
  interface Window {
    analytics: any;
  }
}

const getSegmentScript = (writeKey: string) =>
  writeKey
    ? `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${writeKey}";analytics.SNIPPET_VERSION="4.13.2";` +
      `analytics.load("${writeKey}");` +
      "analytics.page();" +
      "}}();"
    : "";

export const Segment = ({ children }: { children?: React.ReactNode }) => {
  const { SEGMENT_WRITE_KEY } = useConfig();
  React.useEffect(() => {
    let script: HTMLScriptElement;
    if (SEGMENT_WRITE_KEY) {
      script = document.createElement("script");
      script.id = "segment";
      script.async = true;
      script.defer = true;
      script.type = "text/javascript";
      script.text = getSegmentScript(SEGMENT_WRITE_KEY);
      !document.querySelector("#segment") && document.head.appendChild(script);
      return () => {
        document.head.contains(script) && document.head.removeChild(script);
      };
    }
  }, [SEGMENT_WRITE_KEY]);

  return <>{children}</>;
};
