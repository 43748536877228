import { getColor, mode, transparentize } from "@chakra-ui/theme-tools";
import type { StyleFunctionProps } from "@chakra-ui/theme-tools";

const getBg = (props: StyleFunctionProps) => {
  const { theme, colorScheme } = props;

  const lightBg = getColor(theme, `${colorScheme}.50`, colorScheme);
  const darkBg = transparentize(`${colorScheme}.200`, 0.16)(theme);
  return mode(lightBg, darkBg)(props);
};

export default {
  parts: ["container", "title", "description", "icon"],
  baseStyle: {
    title: {
      color: "gray.800",
      marginEnd: 1,
      textStyle: "label",
      lineHeight: 5,
    },
    description: {
      color: "gray.800",
      textStyle: "paragraph",
      lineHeight: 5,
    },
    container: {
      alignItems: "flex-start",
      borderRadius: "base",
      paddingEnd: 2,
      paddingY: 2,
    },
    icon: {
      marginEnd: 3,
      boxSize: 4,
      w: 4,
      h: 4,
    },
  },
  variants: {
    subtle: (props: StyleFunctionProps) => ({
      container: {
        bg: getBg(props),
      },
      icon: {
        color: getColor(
          props.theme,
          `${props.colorScheme}.700`,
          props.colorScheme
        ),
      },
    }),
  },
};
