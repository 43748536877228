import { createContext } from "react";
import type { Userflow } from "userflow.js";

export interface UserflowContextValue {
  userflow: Userflow;
  isReady: boolean;
}

const UserflowContext = createContext<UserflowContextValue>({
  userflow: null,
  isReady: false,
});
export default UserflowContext;
