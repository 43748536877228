const userInteractivesDefaults = {
  borderColor: "gray.300",
  boxShadow: "none",
  borderRadius: "base",
  _placeholder: {
    color: "gray.500",
  },
  _hover: {
    borderColor: "gray.400",
    boxShadow: "none",
  },
  _focus: {
    borderColor: "blue.300",
    boxShadow: "0 0 0 1px var(--chakra-colors-blue-300)",
  },
  _invalid: {
    borderColor: "red.500",
    boxShadow: "none",
    _hover: {
      borderColor: "red.600",
    },
    _focus: {
      borderColor: "red.500",
      boxShadow: "0 0 0 1px var(--chakra-colors-red-500)",
    },
  },
};

export { userInteractivesDefaults };
