import * as React from "react";
import classNames from "classnames";

import { ReactComponent as Disconnect } from "../../icons/Disconnect.svg";

import styles from "./ErrorPage.module.css";

export interface ServerErrorProps {
  children: React.ReactNode;
  className?: string;
}

export const ServerError = ({ children, className }: ServerErrorProps) => {
  return (
    <section className={classNames(styles.flexContainer, className)}>
      <div className={styles.errorInfo}>{children} </div>
      <div>
        <Disconnect className={styles.errorIcon} />
      </div>
    </section>
  );
};
