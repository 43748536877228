import * as React from "react";
import {
  useLDClient,
  asyncWithLDProvider,
} from "launchdarkly-react-client-sdk";

import { PageLoader } from "../../components/PageLoader";
import { useAuthentication, useConfig, useUserDetails } from "../../hooks";

interface CommonProps {
  fallback?: React.ReactNode;
  children: React.ReactNode;
}

export interface FeatureFlagProviderProps extends CommonProps {}

const LDProvider = ({ children }: CommonProps) => {
  const [LDProvider, setLDProvider] = React.useState(null);
  const [config, setConfig] = React.useState(null);
  const { LD_CLIENT_ID } = useConfig();
  const { data: userDetails } = useUserDetails();
  const { isAuthenticated } = useAuthentication();

  const canSetUserDetails = userDetails && isAuthenticated;

  React.useEffect(() => {
    LD_CLIENT_ID &&
      setConfig({
        clientSideID: LD_CLIENT_ID,
        options: {
          sendEvents: !(window as any).Cypress,
        },
      });
  }, [LD_CLIENT_ID]);

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { contexts, ...user } = userDetails || {};

    config &&
      !LDProvider &&
      asyncWithLDProvider({
        ...config,
        ...(canSetUserDetails
          ? {
              user: {
                key: userDetails.userId,
                ...user,
              },
            }
          : {}),
      })
        .then((provider) => {
          setLDProvider(() => provider);
        })
        .catch(console.log);
  }, [config, LDProvider, userDetails, canSetUserDetails]);

  return LDProvider ? <LDProvider>{children}</LDProvider> : <>{children}</>;
};

const LDInitializer = ({
  fallback = <PageLoader />,
  children,
}: CommonProps) => {
  const [initialized, setInitialized] = React.useState(false);
  const ldClient = useLDClient();
  const { BYPASS_LD_INITIALIZATION } = useConfig();

  React.useEffect(() => {
    if (BYPASS_LD_INITIALIZATION || !ldClient) return;
    ldClient
      .waitForInitialization()
      .catch(console.log)
      .finally(() => setInitialized(true));
  }, [ldClient, BYPASS_LD_INITIALIZATION]);

  return initialized || BYPASS_LD_INITIALIZATION ? (
    <>{children}</>
  ) : (
    <>{fallback}</>
  );
};

export const FeatureFlagProvider = ({
  fallback,
  children,
}: FeatureFlagProviderProps) => (
  <LDProvider>
    <LDInitializer {...{ fallback }}>{children}</LDInitializer>
  </LDProvider>
);
