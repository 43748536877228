import type { ComponentStyleConfig } from "@chakra-ui/react";

const parts = ["card", "cardheader", "cardtitle", "cardbody"];

export default {
  parts,
  baseStyle: {
    card: {
      borderWidth: 1,
      borderColor: "gray.300",
      borderRadius: "base",
      mx: "auto",
    },
    cardheader: {
      alignItems: "center",
      justifyContent: "space-between",
      px: 6,
      pt: 6,
      pb: 0,
      color: "heading",
    },
    cardtitle: {
      textStyle: "subtitle",
      pr: 5,
    },
    cardbody: {
      p: 6,
    },
  },
} as ComponentStyleConfig;
