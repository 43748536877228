import type { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export default {
  parts: ["tabpanel", "tab", "indicator", "tablist"],
  baseStyle: (props) => ({
    tab: {
      color: mode("gray.600", "gray.300")(props),
      fontWeight: 600,
    },
    tabpanel: {
      p: 0,
      pt: 4,
    },
  }),
  variants: {
    line: (props) => ({
      tablist: {
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: mode("gray.300", "gray.700")(props),
      },
      tab: {
        fontSize: "1rem",
        lineHeight: "1.5rem",
        _selected: {
          marginBottom: "-2px",
          borderBottomWidth: "2px",
          borderColor: "blue.500",
          color: mode("gray.900", "white")(props),
        },
      },
    }),
  },
} as ComponentMultiStyleConfig;
