import * as React from "react";
import { useQuery } from "@tanstack/react-query";

import { ConfigContext } from "../../../context";
import { Fullstory } from "../../../utils";

const getConfigOverride = (
  { paramKey } = {
    paramKey: "config",
  }
) => {
  const configOverride = new URLSearchParams(location.search)?.get(paramKey);
  let overrides: Record<string, any> = {};
  try {
    if (configOverride) {
      overrides = JSON.parse(`${atob(configOverride)}`);
    }
  } catch {
    console.error(
      "Error parsing config overrides, ensure that you have encoded valid JSON"
    );
  }
  return overrides;
};

/**
 * @param branchPrefix - The 'clean' path segment generated by the preview github action
 * @param appName - Name of the
 * @returns A usable preview URL
 */
const buildPreviewURL = (
  branchPrefix: string,
  appName: "shipper" | "admin" | "facility"
) => `${window.location.protocol}//${branchPrefix}.stord-${appName}.pages.dev`;

export interface ConfigProviderProps {
  children: React.ReactNode;
  initialValue?: Record<string, any>;
}

export const ConfigProvider = ({
  children,
  initialValue = {},
}: ConfigProviderProps) => {
  const { data } = useQuery({
    queryKey: ["getConfig"],
    queryFn: () => fetch("/config.json").then((res) => res.json()),
  });

  const currentHostname = window.location.hostname;

  const previewHosts = React.useMemo(() => {
    const isTeamSpecificEnv = currentHostname.endsWith("rd.stord.com");
    const isPreviewEnv = currentHostname.endsWith("pages.dev");

    // we should only have pages.dev hostnames for previews
    // this will look something like some-clean-branch-url.stord-admin.pages.dev
    // when we're on a team-specific env, we'll have urls like titan.shipper.rd.stord.com
    // in that case, we'll want to take the team name and fix the API_URL
    if (!(isTeamSpecificEnv || isPreviewEnv)) return {};
    const branchPrefixOrTeamName = currentHostname.split(".")[0];
    return {
      ADMIN_HOST: buildPreviewURL(branchPrefixOrTeamName, "admin"),
      SHIPPER_HOST: buildPreviewURL(branchPrefixOrTeamName, "shipper"),
      ...(isTeamSpecificEnv
        ? {
            API_HOST: `https://api.${branchPrefixOrTeamName}.dev.stord.com`,
          }
        : {}),
    };
  }, [currentHostname]);

  const config = React.useMemo(
    () => ({
      ...initialValue,
      ...(data ? data : {}),
      ...getConfigOverride({ paramKey: "config" }),
      ...previewHosts,
    }),
    [data, initialValue, previewHosts]
  );

  React.useEffect(() => {
    const orgId = config?.FULLSTORY_ORG_ID;
    if (orgId) {
      Fullstory.init({ orgId });
    }
  }, [config]);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};
