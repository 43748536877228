export default {
  parts: ["table", "thead", "td", "th"],
  baseStyle: {
    table: {
      borderCollapse: "separate",
      borderSpacing: "0px",
      fontSize: "sm",
      borderRadius: "base",
      border: "none",
    },
    th: {
      background: "gray.100",
    },
  },
  sizes: {
    xs: {
      td: {
        px: 2,
        py: 2,
      },
      th: {
        px: 2,
        py: 2,
      },
    },
  },
  variants: {
    simple: {
      th: {
        fontSize: "xs",
        color: "gray.800",
        letterSpacing: 0,
        textTransform: "none",
        fontWeight: "semibold",
        whiteSpace: "nowrap",
        border: "none",
      },
      td: {
        borderColor: "gray.300",
        textStyle: "paragraph",
      },
    },
  },
};
