import * as React from "react";

import { useUserDetails } from "../../../hooks/useUserDetails";

/**
 *
 * @remarks Many of our components and utils rely on having user details be available. This enforces that this is the case
 * before mounting the rest of the app to prevent potential race conditions and undefined property errors
 */
export const UserDetailsHydrator = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const result = useUserDetails();

  if (result.isInitialLoading) return null;
  return <>{children}</>;
};
