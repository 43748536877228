import * as React from "react";

import { ServerError } from "./ServerError";

import styles from "./ErrorPage.module.css";

export interface ErrorBoundaryPageProps {
  error: Error;
  componentStack?: string;
}
export const ErrorBoundaryPage = ({
  error,
  componentStack,
}: ErrorBoundaryPageProps) => {
  return (
    <ServerError className={styles.fullscreen}>
      <div role="log" style={{ visibility: "hidden", display: "none" }}>
        {error.toString()}
        {componentStack}
      </div>
      <h1>Hmm...</h1>
      <h2>Service Error</h2>
      <p className={styles.message}>
        We&apos;re sorry, our system seems to be having some trouble. Please
      </p>
      <p className={styles.message}>
        check <a href={"https://status.stord.com/"}>Stord Status</a> for
        updates. If you&apos;re still experiencing issues,
      </p>
      <p className={styles.message}>
        please <a href="https://helpdesk.stord.com">contact support</a>.
      </p>
    </ServerError>
  );
};
