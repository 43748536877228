import * as React from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ChakraProvider } from "@chakra-ui/react";

import { theme } from "../../theme";
import { AuthenticationProvider } from "../Authentication/AuthenticationProvider";
import { FeatureFlagProvider } from "../FeatureFlagProvider/FeatureFlagProvider";
import { UserflowProvider } from "../Userflow/UserflowProvider";
import { MaintenanceModePage } from "../ErrorPage/MaintenanceModePage";
import { PageLoader } from "../PageLoader";
import { ErrorBoundaryReloadOnDynamicImport } from "../ErrorBoundary/ErrorBoundary";
import { ApiProvider } from "../../utils/createApi/ApiProvider";
import { PageViewTracker } from "../PageViewTracker/PageViewTracker";
import { DatadogProvider } from "../Datadog/DatadogProvider";

import { ConfigProvider } from "./ConfigProvider/ConfigProvider";
import { Segment } from "./Segment";
import { DocumentHead } from "./DocumentHead";
import { UserDetailsHydrator } from "./UserDetailsHydrator/UserDetailsHydrator";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const StordApp = ({
  children,
  version,
}: {
  children: React.ReactNode;
  version: string;
}) => {
  return (
    <ChakraProvider resetCSS theme={theme}>
      <ErrorBoundaryReloadOnDynamicImport>
        <React.Suspense fallback={<PageLoader />}>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              <ConfigProvider>
                <DatadogProvider version={version}>
                  <DocumentHead />
                  <Segment>
                    <BrowserRouter>
                      <PageViewTracker />
                      <AuthenticationProvider>
                        <ApiProvider>
                          <UserDetailsHydrator>
                            <FeatureFlagProvider>
                              <UserflowProvider>
                                <MaintenanceModePage>
                                  {children}
                                </MaintenanceModePage>
                              </UserflowProvider>
                            </FeatureFlagProvider>
                          </UserDetailsHydrator>
                        </ApiProvider>
                      </AuthenticationProvider>
                    </BrowserRouter>
                  </Segment>
                </DatadogProvider>
              </ConfigProvider>
            </QueryClientProvider>
          </HelmetProvider>
        </React.Suspense>
      </ErrorBoundaryReloadOnDynamicImport>
    </ChakraProvider>
  );
};
