export default {
  baseStyle: {
    item: {
      fontSize: "sm",
      color: "gray.900",
      lineHeight: 4,
      py: 2,
      px: 4,

      "> [class*=icon-wrapper]": {
        fontSize: "1.125em",
      },
    },
    groupTitle: {
      color: "gray.700",
      lineHeight: "0.625rem",
      fontSize: "0.625rem",
      fontWeight: "medium",
      m: 0,
      py: 2,
      px: 4,
    },
    list: {
      borderStyle: "none",
      boxShadow: "md",
      minWidth: "160px",
      borderRadius: "base",
    },
  },
};
