import * as React from "react";
import { Helmet } from "react-helmet-async";

import { useConfig } from "../../../hooks";

export const DocumentHead = (): JSX.Element => {
  const { CDN_HOST } = useConfig();

  if (!CDN_HOST) return null;

  return (
    <Helmet>
      <link rel="icon" href={`${CDN_HOST}/assets/logo-favicon.png`} />
    </Helmet>
  );
};
