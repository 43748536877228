type UserAttributes = {
  firstName: string;
  lastName: string;
  email: string;
  userId: string;
  title?: string;
};

const identifyUser = (userAttributes: UserAttributes): void => {
  const attributes = {
    firstName: userAttributes?.firstName,
    lastName: userAttributes?.lastName,
    email: userAttributes?.email,
    title: userAttributes?.title,
  };

  window?.analytics?.identify(userAttributes?.userId, attributes);
};

export default identifyUser;
