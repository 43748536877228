import { useLDClient } from "launchdarkly-react-client-sdk";

import { useConfig } from "./useConfig";

export * from "../lib/flags";

export const useFlag = <T = any>(flag: string, defaultValue?: T): T => {
  const client = useLDClient();
  const { FEATURE_FLAG_OVERRIDES } = useConfig();

  return (
    (FEATURE_FLAG_OVERRIDES &&
      ((FEATURE_FLAG_OVERRIDES as Record<string, T>)[flag] as T)) ??
    (client ? (client.variation(flag, defaultValue) as T) : defaultValue)
  );
};

export default useFlag;
