import React, { useContext } from "react";
import qs from "query-string";

import { parseCookie } from "../../helpers/formatters";
import { useConfig } from "../../hooks/useConfig";
import { useAuthentication } from "../../hooks/useAuthentication";

import { createApi } from "./createApi";
import type { FetchBaseQueryArgs } from "./fetchBaseQuery";

export type ApiProviderContextValue = {
  client: ReturnType<typeof createApi>;
  jsonApiClient: ReturnType<typeof createApi>;
};
export const ApiProviderContext = React.createContext<ApiProviderContextValue>({
  client: null,
  jsonApiClient: null,
});

/**
 * This is a pre-configured `ApiProvider` that is meant to be used within the normal construction
 * of a Stord Application using ALL fe-components, meaning that it expects to sit between Config/Authentication Providers
 * which is handled by default by `<StordApp />`. You can use it outside of `<StordApp />` by
 * making sure that you have a setup like the below:
 *
 * ```ts
 *  <ConfigProvider>
 *   <AuthenticationProvider>
 *    <ApiProvider>
 *       <App />
 *    </ApiProvider>
 *   </AuthenticationProvider>
 * </ConfigProvider>
 * ```
 *
 * For other implementations, just modify this ApiProvider as you see fit or do something else altogether. Please note,
 * you can not mix and mismatch providers from fe-components that rely on each other, alongside of your own. If you are
 * going the totally custom route, you should handle your own equivalent of useConfig, useAuthentication, etc etc. Mixing
 * providers/hooks from fe-components with your own can and will lead to undefined context values. When in doubt,
 * just roll your own providers/hooks and use this file as a boilerplate example.
 *
 * @param clientOverrides - Overrides for the `createApi` function in the case the default behavior does not work as expected.
 * The only thing that should really change is `paramsSerializer` depending on the API.
 */
export const ApiProvider = ({
  children,
  clientOverrides,
}: {
  children: React.ReactNode;
  clientOverrides?: FetchBaseQueryArgs;
}) => {
  const { API_HOST } = useConfig();
  const { getAccessTokenSilently } = useAuthentication();

  const baseArgs: FetchBaseQueryArgs = {
    baseUrl: API_HOST,
    prepareHeaders: async (headers) => {
      // If we're in e2e, just use the cookie that gets set in Cypress
      const e2e = parseCookie("e2e");
      const token = e2e?.token ?? (await getAccessTokenSilently());

      headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
    isJsonContentType: (headers) =>
      ["application/vnd.api+json", "application/json"].includes(
        headers.get("content-type")
      ),
    paramsSerializer: qs.stringify,
  };

  // Setup the Orion V2+ API client
  const client = createApi({ ...baseArgs, ...clientOverrides });

  // Setup the Orion V1 API client that uses JSONAPI
  const jsonApiClient = createApi({
    ...baseArgs,
    jsonContentType: "application/vnd.api+json",
    ...clientOverrides,
  });

  return (
    <ApiProviderContext.Provider value={{ client, jsonApiClient }}>
      {client ? children : null}
    </ApiProviderContext.Provider>
  );
};

/**
 * @returns An API instance that automatically sets the correct headers
 */
export const useApi = () => useContext(ApiProviderContext).client;
/**
 * @returns An API instance that automatically sets the correct authorization and JSONAPI headers
 */
export const useJsonApi = () => useContext(ApiProviderContext).jsonApiClient;
