import { Tooltip } from "@chakra-ui/react";
import type { TooltipProps } from "@chakra-ui/react";

import typography from "../foundations/typography";

const tooltipProps: Omit<TooltipProps, "children"> = {
  ...Tooltip.defaultProps,
  ...typography.textStyles.tooltip,
  hasArrow: true,
  color: "gray.100",
  bg: "tooltip-bg",
};
Tooltip.defaultProps = tooltipProps;

/**
 * @remarks This looks strange, because it is. Chakra has a limitation on to what and how we can theme things, so we have to set the default props like we're doing above. This export is kept for consistency.
 * @see https://github.com/chakra-ui/chakra-ui/issues/1424
 */
export default {};
