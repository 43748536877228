/* eslint-disable prefer-const */

/**
 * This is a small convenience wrapper around `fetchBaseQuery` to provide some pre-bound method handlers such as:
 * `client.get`, `client.patch`, `client.post`, etc.
 *
 * Additionally, it'll behave just like an `axios.create()` so you can use the instance with code generators like `orval`
 * or however you'd like.
 */
import { fetchBaseQuery } from "./fetchBaseQuery";
import type { FetchArgs, FetchBaseQueryArgs } from "./fetchBaseQuery";
import { unwrap } from "./utils";

const requestMethods = ["GET", "PUT", "PATCH", "POST", "DELETE"] as const;
export type BaseOverrides = { baseOverrides?: Partial<FetchBaseQueryArgs> };
export type RequestMethod = typeof requestMethods[number];
export type RequestFn = <Response>(
  options: FetchArgs & BaseOverrides
) => Promise<Response>;

export const createApi = (options: FetchBaseQueryArgs) => {
  /**
   *
   * @param arg Either a string url to a resource or a request config object
   */
  const instance = <Response>(arg: string | (FetchArgs & BaseOverrides)) =>
    fetchBaseQuery({ ...options })(
      typeof arg === "string" ? { url: arg } : arg
    ).then((r) => unwrap<Response>(r));
  return Object.assign(
    instance,
    requestMethods.reduce((acc, method) => {
      acc[method.toLowerCase() as Lowercase<RequestMethod>] = async <Response>({
        baseOverrides,
        ...fetchOptions
      }: FetchArgs & BaseOverrides) =>
        fetchBaseQuery<Response>({
          ...options,
          ...baseOverrides,
        })({ method, ...fetchOptions }).then((r) => unwrap<Response>(r));
      return acc;
    }, {} as Record<Lowercase<RequestMethod>, RequestFn>)
  );
};
