import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const PageViewTracker = (): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    window?.analytics?.page();
  }, [location]);

  return null;
};
