import { useQuery } from "@tanstack/react-query";
import type { UseQueryResult } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";

import { useDatadogRUM } from "../../components/Datadog/DatadogProvider";
import type { ResourceObject, Response } from "../../types/jsonApi";
import { can } from "../../components/Authorization/authUtils";
import { Fullstory } from "../../utils/fullstory";
import { useAuthentication } from "../useAuthentication";
import { useJsonApi } from "../../utils/createApi";

import type { UserDetails } from "./types";
import identifyUser from "./identifyUser";

type GetUserDetailsResponse = Response<ResourceObject<UserDetails>>;

/**
 * Pulls the details for the current logged in user
 * @remarks The properties on this return are _guaranteed_ to be present. If we fail to load a user, the app will not load.
 */
export const useUserDetails = (): UseQueryResult<UserDetails, unknown> & {
  data: UserDetails;
} => {
  const api = useJsonApi();
  const history = useHistory();
  const { isAuthenticated } = useAuthentication();
  const datadogRum = useDatadogRUM();

  return useQuery({
    queryKey: ["UserDetails"],
    queryFn: async () => {
      const result = await api.get<GetUserDetailsResponse>({
        url: "/v1/userdetails",
      });
      return result.data.attributes;
    },
    select: (user) => {
      return {
        ...user,
        isAdmin: can(user, "app.admin.view", [
          "11111111-1111-1111-1111-111111111111",
        ]),
      };
    },
    enabled: isAuthenticated,
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    onError: () => {
      history.push("/auth_error?login=true");
    },
    onSuccess: (user) => {
      identifyUser(user);

      const fullname = `${user.firstName} ${user.lastName}`;

      Fullstory.identify(user.userId, {
        displayName: fullname,
        userDomain: user.email.split("@")[1],
        companyId: user.companyId,
      });

      datadogRum?.setUser({
        id: user.userId,
        email: user.email,
        name: fullname,
        companyId: user.companyId,
        companyTenantVersion: user.activeTenantId ? 2 : 1,
      });
    },
  });
};
