import * as React from "react";

import { ReactComponent as Maintenance } from "../../icons/Maintenance.svg";
import { Logo } from "../../components/Logo";
import useFlag from "../../hooks/useFlag";

import styles from "./MaintenanceMode.module.css";

export interface MaintenanceModePageProps {
  children?: React.ReactNode;
}

export const MaintenanceModePage = ({ children }: MaintenanceModePageProps) => {
  const isMaintenanceModeActive = useFlag("maintenance-mode-active", false);

  if (isMaintenanceModeActive) {
    return (
      <section className={styles.maintenance}>
        <main>
          <div>
            <Logo className={styles.logo} />
            <Maintenance className={styles.icon} />
            <h1>We&apos;ll be back soon!</h1>
            <p>
              Our site is undergoing some scheduled maintenance, we should be
              back shortly. We apologize for any inconvenience.
            </p>
          </div>
        </main>
      </section>
    );
  }

  return <>{children}</>;
};
