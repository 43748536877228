import type { ComponentSingleStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export default {
  baseStyle: (props) => ({
    height: 2,
    opacity: 1,
    borderColor: mode("gray.300", "gray.700")(props),
  }),
} as ComponentSingleStyleConfig;
