export default {
  fonts: {
    heading: `"Inter", sans-serif`,
    body: `"Inter", sans-serif`,
    mono: `"Inter", sans-serif`,
  },
  textStyles: {
    pageTitle: {
      lineHeight: 8,
      fontSize: "3xl",
      fontWeight: "bold",
    },
    title: {
      lineHeight: 8,
      fontSize: "1.375rem",
      fontWeight: "semibold",
    },
    sectionTitle: {
      lineHeight: 6,
      fontSize: "md",
      fontWeight: "semibold",
    },
    subtitle: {
      lineHeight: 6,
      fontSize: "lg",
      fontWeight: "semibold",
    },
    label: {
      lineHeight: 5,
      fontSize: "sm",
      fontWeight: "semibold",
    },
    paragraph: {
      lineHeight: 5,
      fontSize: "sm",
      fontWeight: "normal",
    },
    tooltip: {
      lineHeight: 4,
      fontSize: "xs",
      fontWeight: "semibold",
    },
    caption: {
      lineHeight: 4,
      fontSize: "xs",
      fontWeight: "normal",
    },
    miniHeader: {
      lineHeight: 3,
      fontSize: "0.625rem",
      fontWeight: "bold",
      textTransform: "uppercase",
      letterSpacing: "0.06em",
    },
  },
};
