export default {
  baseStyle: {
    control: {
      borderWidth: "0.125rem",
      borderColor: "gray.300",
      _hover: {
        borderColor: "gray.400",
      },
      _disabled: {
        borderColor: "gray.300",
        bg: "gray.200",
        _checked: {
          borderColor: "gray.400",
          bg: "gray.400",
          color: "gray.700",
        },
      },
    },
  },
};
